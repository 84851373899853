import { Component, OnInit } from '@angular/core';
import { GetStartedService } from 'app/website/services/get-started.service';

@Component({
  selector: 'app-try-for-free-button',
  templateUrl: './try-for-free-button.component.html',
  styleUrls: ['./try-for-free-button.component.scss']
})
export class TryForFreeButtonComponent implements OnInit {

  constructor(public getStartedService: GetStartedService) { }

  ngOnInit(): void {
  }
  callNow(){
    window.location.href = "tel:+18559108235";
  }

}
