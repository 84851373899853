import { ToastModule } from 'ng-uikit-pro-standard';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA, Injectable } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes.service';

import { HttpClientModule  } from '@angular/common/http';

import { ViewsModule } from './views/views.module';
import { SharedModule } from './shared/shared.module';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { NgxCookieBannerModule } from 'ngx-cookie-banner';

import { ErrorModule } from './views/errors/error.module';

import { NgxCurrencyModule } from "ngx-currency";

// main layout
import { IndexComponent } from './website/components/index/index.component';
import { SiteNavbarComponent } from './website/components/navbar/navbar.component';
import { SiteFooterComponent } from './website/components/site-footer/site-footer.component';
import { HowWorksComponent } from './website/components/how-works/how-works.component';
import { MoreFeatureComponent } from './website/components/more-feature/more-feature.component';
import { FeatureSectionComponent } from './website/components/feature-section/feature-section.component';
import { CarouselCaptionComponent } from './website/components/carousel-caption/carousel-caption.component';
import { UseCaseComponent } from './website/components/use-case/use-case.component';
import { SitePricingComponent } from './website/components/pricing/pricing.component';
import { FaqComponent } from './website/components/faq/faq.component';
import { QuestionnaireComponent } from './website/components/questionnaire/questionnaire.component';
import { GetStartedComponent } from './website/components/get-start/get-started.component';
import { GoPaperlessComponent } from './website/components/go-paperless/go-paperless.component';
import { DocumentManagementComponent } from './website/components/document-management/document-management.component';
import { AutomateWorkflowsComponent } from './website/components/automate-workflows/automate-workflows.component';
import { DataReportsAndAnalyticsComponent } from './website/components/data-reports-and-analytics/data-reports-and-analytics.component';

import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { WorkflowManagementComponent } from './website/landing-pages/workflow-management/workflow-management.component';
import { ForDocumentManagementComponent } from './website/landing-pages/for-document-management/for-document-management.component';
import { EnterpriseContentManagementComponent } from './website/landing-pages/enterprise-content-management/enterprise-content-management.component';
import { BusinessProcessManagementComponent } from './website/landing-pages/business-process-management/business-process-management.component';
import { FormsAutomationComponent } from './website/landing-pages/forms-automation/forms-automation.component';

import { PrivacyPolicyComponent } from './website/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './website/terms-of-service/terms-of-service.component';
import { CookieStatementComponent } from './website/cookie-statement/cookie-statement.component';
import { CalculateRoiPageComponent } from './website/components/calculate-roi-page/calculate-roi-page.component';
import { RoiCalculatorComponent } from './website/components/roi-calculator/roi-calculator.component';
import { ManufacturingComponent } from './website/components/industries/manufacturing/manufacturing.component';
import { ConstructionComponent } from './website/components/industries/construction/construction.component';
import { InspectionAuditingComponent } from './website/components/industries/inspection-auditing/inspection-auditing.component';
import { AboutUsComponent } from './website/components/about-us/about-us.component';
import { ReplaceSpreadsheetsComponent } from './website/components/replace-spreadsheets/replace-spreadsheets.component';
import { EFormsComponent } from './website/components/e-forms/e-forms.component';
import { AccountingFinanceComponent } from './website/components/industries/accounting-finance/accounting-finance.component';
import { InsuranceComponent } from './website/components/industries/insurance/insurance.component';
import { HumanResourcesComponent } from './website/components/industries/human-resources/human-resources.component';
import { LawFirmsComponent } from './website/components/industries/law-firms/law-firms.component';
import { OilEnergyComponent } from './website/components/industries/oil-energy/oil-energy.component';
import { PharmaceuticalsComponent } from './website/components/industries/pharmaceuticals/pharmaceuticals.component';
import { RealEstateComponent } from './website/components/industries/real-estate/real-estate.component';
import { RetailComponent } from './website/components/industries/retail/retail.component';
import { SupplyChainLogisticsComponent } from './website/components/industries/supply-chain-logistics/supply-chain-logistics.component';
import { TryEcodocsComponent } from './website/components/try-ecodocs/try-ecodocs.component';
import { TrainingAndDevelopmentComponent } from './website/components/industries/training-and-development/training-and-development.component';
import { EmbeddedWordpressArticlesComponent } from './website/components/embedded-wordpress-articles/embedded-wordpress-articles.component';
import { TryForFreeButtonComponent } from './website/components/try-for-free-button/try-for-free-button.component';
import { OnboardingSectionComponent } from './website/components/onboarding-section/onboarding-section.component';

declare var Hammer: any;

@Injectable({ providedIn: 'root' })
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    'pan': { direction: Hammer.DIRECTION_All },
    'swipe': { direction: Hammer.DIRECTION_VERTICAL },
  };

  buildHammer(element: HTMLElement) {
    const mc = new Hammer(element, {
      touchAction: 'auto',
          inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput,
          recognizers: [
            [Hammer.Swipe, {
              direction: Hammer.DIRECTION_HORIZONTAL
            }]
          ]
    });
    return mc;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    SiteNavbarComponent,
    SiteFooterComponent,
    HowWorksComponent,
    MoreFeatureComponent,
    FeatureSectionComponent,
    CarouselCaptionComponent,
    UseCaseComponent,
    SitePricingComponent,
    FaqComponent,
    QuestionnaireComponent,
    GetStartedComponent,
    GoPaperlessComponent,
    DocumentManagementComponent,
    AutomateWorkflowsComponent,
    DataReportsAndAnalyticsComponent,
    WorkflowManagementComponent,
    ForDocumentManagementComponent,
    EnterpriseContentManagementComponent,
    BusinessProcessManagementComponent,
    FormsAutomationComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent,
    CookieStatementComponent,
    CalculateRoiPageComponent,
    RoiCalculatorComponent,
    ManufacturingComponent,
    ConstructionComponent,
    InspectionAuditingComponent,
    AboutUsComponent,
    ReplaceSpreadsheetsComponent,
    EFormsComponent,
    AccountingFinanceComponent,
    InsuranceComponent,
    HumanResourcesComponent,
    LawFirmsComponent,
    OilEnergyComponent,
    PharmaceuticalsComponent,
    RealEstateComponent,
    RetailComponent,
    SupplyChainLogisticsComponent,
    TryEcodocsComponent,
    TrainingAndDevelopmentComponent,
    EmbeddedWordpressArticlesComponent,
    TryForFreeButtonComponent,
    OnboardingSectionComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    FormsModule,
    SharedModule,
    ViewsModule,
    ErrorModule,
    ToastModule.forRoot(),
    ReactiveFormsModule,
    NgxCookieBannerModule.forRoot({
      cookieName: 'ecodocs-cookie-banner',
    }),
    NgxCurrencyModule
  ],
  providers: [
    MDBSpinningPreloader,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    }
  ],
  bootstrap: [AppComponent],
  schemas: [ NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
