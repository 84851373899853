import { Directive, Input, ElementRef, AfterViewInit, ViewContainerRef, ComponentFactoryResolver, ComponentRef } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Observable, of, pipe, fromEvent, combineLatest } from 'rxjs';
import { ValidationHandler } from 'app/helpers/validation.handler';
import { FormGroup, FormBuilder, Validators, FormControl, NgControl } from '@angular/forms';
import { ValidationMessagesComponent } from '../components/validation-messages/validation-messages.component';

@Directive({
    selector: '[validationHandler]',
})
export class ValidationHandlerDirective {
    constructor(private viewContainerRef: ViewContainerRef,
                private resolver: ComponentFactoryResolver,
                private ngControl : NgControl) 
    {
            let factory = this.resolver.resolveComponentFactory(ValidationMessagesComponent);
            this.validationMessages = this.viewContainerRef.createComponent(factory);
            this.validationMessages.instance.validationLabel = this.validationLabel || ngControl.name as string;
            this.validationMessages.instance.control = this.ngControl;
    }
    _validationLabel: string = null;
    validationMessages: ComponentRef<ValidationMessagesComponent>;

    @Input('validationLabel') public set validationLabel(v : string) {
        this.validationMessages.instance.validationLabel = v || this.ngControl.name as string;
        this._validationLabel = v;
    }
    
    
    public get validationLabel() : string {
        return this._validationLabel;
    }
    
}

