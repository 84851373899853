import { Component, OnInit } from '@angular/core';
import { FeatureSummary } from 'app/website/models/feature-summary.model';

@Component({
  selector: 'app-forms-automation',
  templateUrl: './forms-automation.component.html',
  styleUrls: ['./forms-automation.component.scss']
})
export class FormsAutomationComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

  moreFeatures: FeatureSummary[] = [
    {
      title: 'Highly Secure',
      desciption: 'Configure users and teams and define custom permissions and policies.',
      icon: 'fas fa-3x fa-user-shield'
    },
    {
      title: 'Easy Search',
      desciption: 'Sort, categorize, apply OCR and text extraction for full-text search on all documents. ',
      icon: 'fas fa-3x fa-search'
    },
    {
      title: 'Compatibility',
      desciption: 'Easy integration with any on-premises storage through FTP, SFTP, and WebDAV.',
      icon: 'fas fa-3x fa-check-double'
    },
    {
      title: 'Forms Builder',
      desciption: 'Easily build complicated forms used in any industry with the drag-and-drop forms builder.',
      icon: 'fab fa-3x fa-wpforms'
    },
    {
      title: 'Auto Validation',
      desciption: 'Automatic data validation using Custom logic, Relation-based, Value-based, or conditional validation.',
      icon: 'far fa-3x fa-check-circle'
    },
    {
      title: 'Workflow Templates',
      desciption: 'Customize your workflow with ready-made broad templates to get you started.',
      icon: 'fas fa-3x fa-project-diagram'
    },
    {
      title: 'Custom Workflows',
      desciption: 'A visual workflow template builder to create custom workflows with multi-step approval and review processes.',
      icon: 'fas fa-3x fa-sitemap'
    },
    {
      title: 'Real-time Analytics',
      desciption: 'As soon as forms are filled and submitted, the filled data is available to be exported in reports.',
      icon: 'far fa-3x fa-chart-bar'
    }
  ]
}
