import { Component, OnInit, Renderer2, AfterViewInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { ToastService, ModalDirective } from "ng-uikit-pro-standard";
import { NgxCookieBannerComponent } from 'ngx-cookie-banner';
import { UserAnswer, Question } from './website/models/question.model';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { GetStartedService } from './website/services/get-started.service';

declare var gtag: Function;

@Component({
  selector: 'mdb-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit {

  private currentUrl = '';
  private toContactId: string = null;

  @ViewChild('questionnaireModal', { static: true }) questionnaireModal: ModalDirective;
  @ViewChild('cookieBanner') cookieBanner: NgxCookieBannerComponent;

  isLandingPage: boolean = false;
  isChatInitialized: boolean = false;

  userAnswer: UserAnswer[] = [];
  
  questions: Question = {
    question: 'How do you manage documents and paper work in your business?',
    answers: [
      {
        answerText: 'We still use physical papers in our buisness',
        nextQuestion: {
          question: 'next Question?',
          answers: [
            {
              answerText: 'answer 1',
            },
            {
              answerText: 'answer 2',
            },

          ]
        } 
      },
      {
        answerText: 'We already use electronic documents (word, pdf ..etc)',
      },
      {
        answerText: 'Our system create highly customizable forms',
      }
    ]
  }

  constructor(
    private location: Location,
    private toastService: ToastService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private getStartedService: GetStartedService
  ) {

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((route:any) => {
      let currentUrl = route.url;

      this.isLandingPage = false;

      if (currentUrl.indexOf("/landing-pages") !== -1) {
        this.isLandingPage = true;
      }
    });

    this.router.events.subscribe(event => {
      try {
        if (event instanceof NavigationEnd) {
          this.toContactId = this.activatedRoute.snapshot.queryParams.to_cid || localStorage.getItem('toContactId');
          if (this.activatedRoute.snapshot.queryParams.to_cid) {
            localStorage.setItem('toContactId', this.toContactId);
          }
      
          var urlParamStartIndex = event.urlAfterRedirects.indexOf('?');
          if (urlParamStartIndex == -1)
            urlParamStartIndex = undefined;
          var pagePath = event.urlAfterRedirects.substring(0, urlParamStartIndex);
          if (!pagePath || pagePath == '')
            pagePath = '/';

          if (this.activatedRoute.snapshot.queryParams.to_cid) {
            // Remove query params
            this.router.navigate([window.location.pathname], {
              queryParams: {
                'to_cid': null
              },
              queryParamsHandling: 'merge'
            });
          }

          if (this.toContactId && !this.activatedRoute.snapshot.queryParams.to_cid) {
            if (this.toContactId.startsWith('cont_')) {
              this.getStartedService.reportPageview(this.toContactId, pagePath).subscribe();
            }
          }
          
          if (typeof gtag === 'function') {
            gtag('config', 'UA-161845474-1', 
                   {
                     'page_path': event.urlAfterRedirects
                   }
                  );
          }
        }
      } catch (e) {
        console.log(e);
      }

      
    });    
  }

  ngOnInit(): void {
    
  }
  ngAfterViewInit() {
    this.cookieBanner.isSeen.subscribe((isAccepted) => {
      if (environment.production && isAccepted) { 
        try {
          if (typeof gtag === 'function') {
            // If cookie is accepted, starting collecting personalized signals
            gtag('set', 'allow_ad_personalization_signals', true );
          }
        } catch (ex) {
          console.error('Error appending google analytics');
          console.error(ex);
        } 
        
        try {
          const script1 = document.createElement('script');
          script1.innerHTML = `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window,document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '1520447414935971'); 
          fbq('track', 'PageView');
          `;
          document.head.appendChild(script1);
          
          const script2 = document.createElement('noscript');
          script2.innerHTML = `
          <img height="1" width="1" 
          src="https://www.facebook.com/tr?id=1520447414935971&ev=PageView
          &noscript=1"/>
          `;
          document.head.appendChild(script2);
        } catch (ex) {
          console.error('Error appending facebook tracking');
          console.error(ex);
        } 
      }      
    });    
  }
  goBack(): void {
    this.location.back();
  }
}
