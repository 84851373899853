import { RouterModule, Route } from '@angular/router';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NotFoundComponent } from './views/errors/not-found/not-found.component';

import { IndexComponent } from './website/components/index/index.component';
import { UseCaseComponent } from './website/components/use-case/use-case.component';
import { FaqComponent } from './website/components/faq/faq.component';
import { SitePricingComponent } from './website/components/pricing/pricing.component';
import { GoPaperlessComponent } from './website/components/go-paperless/go-paperless.component';
import { DocumentManagementComponent } from './website/components/document-management/document-management.component';
import { AutomateWorkflowsComponent } from './website/components/automate-workflows/automate-workflows.component';
import { DataReportsAndAnalyticsComponent } from './website/components/data-reports-and-analytics/data-reports-and-analytics.component';
import { WorkflowManagementComponent } from './website/landing-pages/workflow-management/workflow-management.component';
import { ForDocumentManagementComponent } from './website/landing-pages/for-document-management/for-document-management.component';
import { EnterpriseContentManagementComponent } from './website/landing-pages/enterprise-content-management/enterprise-content-management.component';
import { BusinessProcessManagementComponent } from './website/landing-pages/business-process-management/business-process-management.component';
import { FormsAutomationComponent } from './website/landing-pages/forms-automation/forms-automation.component';
import { PrivacyPolicyComponent } from './website/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './website/terms-of-service/terms-of-service.component';
import { CookieStatementComponent } from './website/cookie-statement/cookie-statement.component';
import { CalculateRoiPageComponent } from './website/components/calculate-roi-page/calculate-roi-page.component';
import { ManufacturingComponent } from './website/components/industries/manufacturing/manufacturing.component';
import { ConstructionComponent } from './website/components/industries/construction/construction.component';
import { InspectionAuditingComponent } from './website/components/industries/inspection-auditing/inspection-auditing.component';
import { AboutUsComponent } from './website/components/about-us/about-us.component';
import { ReplaceSpreadsheetsComponent } from './website/components/replace-spreadsheets/replace-spreadsheets.component';
import { EFormsComponent } from './website/components/e-forms/e-forms.component';
import { AccountingFinanceComponent } from './website/components/industries/accounting-finance/accounting-finance.component';
import { HumanResourcesComponent } from './website/components/industries/human-resources/human-resources.component';
import { InsuranceComponent } from './website/components/industries/insurance/insurance.component';
import { LawFirmsComponent } from './website/components/industries/law-firms/law-firms.component';
import { OilEnergyComponent } from './website/components/industries/oil-energy/oil-energy.component';
import { PharmaceuticalsComponent } from './website/components/industries/pharmaceuticals/pharmaceuticals.component';
import { RealEstateComponent } from './website/components/industries/real-estate/real-estate.component';
import { RetailComponent } from './website/components/industries/retail/retail.component';
import { SupplyChainLogisticsComponent } from './website/components/industries/supply-chain-logistics/supply-chain-logistics.component';
import { TryEcodocsComponent } from './website/components/try-ecodocs/try-ecodocs.component';
import { TrainingAndDevelopmentComponent } from './website/components/industries/training-and-development/training-and-development.component';

const routes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    component: IndexComponent,
    data: {
      title: 'AI-Powered Automation, Data Management & Business Intelligence',
      description: 'Reduce manual work with AI-Powered Automation and Data Management. Disruptively automate business processes with AI-Powered Automation and Data Management.',
      ogUrl: ''
    }
  },
  { path: 'site/pricing', pathMatch: 'full', redirectTo: 'pricing' },
  { path: 'plans', pathMatch: 'full', redirectTo: 'pricing' },
  // {
  //   path: 'usecases',
  //   pathMatch: 'full',
  //   component: UseCaseComponent
  // },
  { path: 'try-ecodocs', pathMatch: 'full', redirectTo: '' },
  { path: 'eliminate-spreadsheets', pathMatch: 'full', redirectTo: '' },
  { path: 'document-management', pathMatch: 'full', redirectTo: '' },
  { path: 'business-process-automation', pathMatch: 'full', redirectTo: '' },
  { path: 'data-reports-and-analytics', pathMatch: 'full', redirectTo: '' },
  
  {
    path: 'pricing',
    pathMatch: 'full',
    component: SitePricingComponent,
    data: {
      title: 'Pricing',
      description: 'Pay only for the features you need. EcoDocs flexible pricing allows you to get the most within your budget.',
      ogUrl: ''
    }
  },
  {
    path: 'about-ecodocs',
    pathMatch: 'full',
    component: AboutUsComponent,
    data: {
      title: 'Our Story',
      description: 'The Story behind EcoDocs, the AI-powered automation and data management software.',
      ogUrl: ''
    }
  },
  // {
  //   path: 'faq',
  //   pathMatch: 'full',
  //   component: FaqComponent
  // },
  {
    path: 'privacy-policy',
    pathMatch: 'full',
    component: PrivacyPolicyComponent
  },
  {
    path: 'terms-of-service',
    pathMatch: 'full',
    component: TermsOfServiceComponent
  },
  {
    path: 'cookie-statement',
    pathMatch: 'full',
    component: CookieStatementComponent
  },

  {
    path: 'industries',
    children: [
      {
        path: 'manufacturing',
        component: ManufacturingComponent,
        data: {
          title: 'EcoDocs for Manufacturing: Simplify Your Operations with Smart Business Process Automation',
          description: 'Take the guesswork out of manufacturing processes with our intelligent automation system. Gain real-time insights and make data-driven decisions for improved productivity and profitability.',
          ogUrl: ''
        }
      },
      {
        path: 'construction',
        component: ConstructionComponent,
        data: {
          title: 'Construction Business Process Automation and Field-to-Office communications',
          description: 'Maintain field-to-office communications, automate your invoice processing, and increase your bottom line. Get a free demo!',
          ogUrl: ''
        }
      },
      {
        path: 'training-and-development',
        component: TrainingAndDevelopmentComponent,
        data: {
          title: 'EcoDocs for Training and Development is like no other LMS',
          description: 'Automate and personalize your learning journeys to keep your trainees engaged using EcoDocs . Get a free demo!',
          ogUrl: ''
        }
      },
      {
        path: 'accounting-finance',
        component: AccountingFinanceComponent,
        data: {
          title: 'EcoDocs for Finance: Automate Invoice reviews, APs, ARs and POs using advanced AI',
          description: 'EcoDocs is the ultimate solution for businesses seeking to streamline their accounting and finance departments. Our advanced automation system ensures precise 3-way matching and frees up valuable time for your team.',
          ogUrl: ''
        }
      },
      {
        path: 'human-resources',
        component: HumanResourcesComponent,
        data: {
          title: 'EcoDocs for HR: Transform Your HR Department with Cutting-Edge Business Process Automation',
          description: 'Streamline your HR processes with our intelligent business process automation system. Say goodbye to manual tasks and hello to efficiency.',
          ogUrl: ''
        }
      },
      {
        path: 'supply-chain-logistics',
        component: SupplyChainLogisticsComponent,
        data: {
          title: 'Supply Chain Intelligent Automation',
          description: 'Boost Your Supply Chain Efficiency with EcoDocs: The Intelligent Process Automation System. Transform your business with intelligent automation to enhance speed, accuracy and reduce errors.',
          ogUrl: ''
        }
      },         
    ]
  },  

  { 
    path: '**', component: NotFoundComponent,
    data: {
      title: 'Page Not Found',
      description: '',
      ogUrl: ''
    }
  },  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }