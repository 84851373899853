import { Component, OnInit, Input } from '@angular/core';
import { CarouselCaption } from 'app/website/models/carousel-caption.model';

@Component({
  selector: 'site-carousel-caption',
  templateUrl: './carousel-caption.component.html',
  styleUrls: ['./carousel-caption.component.scss']
})
export class CarouselCaptionComponent implements OnInit {

  @Input() caption: CarouselCaption;
  constructor() { }

  ngOnInit() {
  }

}
