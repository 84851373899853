import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, ValidationErrors, NgControl } from '@angular/forms';
import { ValidationHandler } from 'app/helpers/validation.handler';

@Component({
  selector: 'app-validation-messages',
  templateUrl: './validation-messages.component.html',
  styleUrls: ['./validation-messages.component.scss']
})
export class ValidationMessagesComponent implements OnInit {

  constructor(private validationHandler: ValidationHandler) { }

  @Input() control: NgControl = null;
  @Input() validationLabel: string;

  formatErrors() {
    return this.validationHandler.handleErrors(this.control.control, this.validationLabel);
  }

  ngOnInit(): void {
  }

}
