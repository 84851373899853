import { Injectable } from '@angular/core'
import { FormControl, FormGroup, AbstractControl, FormArray } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class ValidationHandler {

    handleErrors(formControl: AbstractControl, label: string) {
        let errors = [];
        if (formControl.errors) {
            if (formControl.errors.required)
                errors.push(label + " is required!");
            if (formControl.errors.freeEmail)
                errors.push("Please enter a valid work email!");
            if (formControl.errors.unique)
                errors.push("This " + label + " has already been taken!");
            if (formControl.errors.maxlength)
                errors.push(label + " is too long!");
            if (formControl.errors.email)
                errors.push("Please enter a valid email!");
            if (formControl.errors.minlength)
                errors.push("Length should be at least " + formControl.errors.minlength.requiredLength + " characters.");
            if (formControl.errors.workflowFileUniqueName)
                errors.push("Name " + formControl.errors.workflowFileUniqueName.value + " has already been taken!");
            if (formControl.errors.workflowFormUniqueName)
                errors.push("Name " + formControl.errors.workflowFormUniqueName.value + " has already been taken!");
            if (formControl.errors.workflowStepCompletionUniqueName)
                errors.push("A Decision with the same name already exists!");
            if (formControl.errors.workflowStepUniqueName)
                errors.push("A Step with the same name already exists!");
            if (formControl.errors.server_error) {
                if (formControl.errors.server_error instanceof Array)
                    errors.push(...formControl.errors.server_error);
                else
                    errors.push(formControl.errors.server_error);
            }
        }
        
        return errors;
    }

    serverValidationHandler(errors: any, formObject: FormGroup) {
        let errorsKeys = Object.keys(errors)
        errorsKeys.forEach(errorKey => {
            formObject.markAsTouched();
            if (formObject.get(errorKey))
                formObject.get(errorKey).setErrors({'server_error': errors[errorKey]});
        });
    }

    onSubmitCheck(frm: FormGroup | FormArray, recursive: boolean = true) {
        let inputs = Object.keys(frm.controls);
        inputs.forEach(element => {
            const abstractControl = frm.controls[element];
            if (abstractControl) {
                abstractControl.markAsDirty();
                abstractControl.updateValueAndValidity();

                if (recursive && abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
                    this.onSubmitCheck(abstractControl);
                }
            }
        });
    }
}