import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WordPressPost } from '../models/word-press-post.model';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class WordPressService {

  constructor(private http: HttpClient) { }

  getPosts(category_id: string) {
    return this.http.get<any[]>(`https://blog.ecodocs.io/index.php/wp-json/wp/v2/posts/`, {
      params: {
        categories: category_id,
        _fields: 'categories,link,title,excerpt,featured_media,_links',
        _embed: 'wp:featuredmedia'
      }
    }).pipe(map((r) => {
      let posts: WordPressPost[] = [];
      r.forEach((rp) => {
        posts.push({
          link: rp.link,
          title: rp.title.rendered,
          excerpt: rp.excerpt.rendered,
          featured_media_url: rp._embedded['wp:featuredmedia'][0]['source_url']
        });
      });
      return posts;
    }));
  }
}
