import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidationHandler } from 'app/helpers/validation.handler';
import { Router, Event, NavigationEnd, ActivatedRoute } from '@angular/router';
import { GetStarted } from 'app/website/models/get-started.model';
import { GetStartedService } from 'app/website/services/get-started.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ToastService } from 'ng-uikit-pro-standard';
import { filter, map, mergeMap } from 'rxjs/operators';
import { EcoDocsTitleService } from 'app/services/ecodocs-title.service';
import * as freeEmailDomains from 'free-email-domains';

@Component({
  selector: 'site-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SiteNavbarComponent implements OnInit {
  showGettingStart: boolean = false;
  getStartForm: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email, (c: FormControl) => {
      let domain = (c.value || '@').split('@')[1];
      if (freeEmailDomains.includes(domain)) {
        return {freeEmail: true};
      } else {
        return null;
      }
    }]],
    password: ['', [Validators.required, Validators.minLength(6)]]
  })
  chosenPlan: any;
  loading: boolean = false;

  constructor(private formBuilder: FormBuilder, private _validationHandler:ValidationHandler, public getStartedService: GetStartedService,
    private router: Router, private activeRoute: ActivatedRoute, private toastrService: ToastService,
    private ecoDocsTitleService: EcoDocsTitleService) { 
  
    router.events.subscribe( (event: Event) =>{
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });

    getStartedService.choosenPlan.subscribe(r => {
      if(r != ''){
        this.chosenPlan = r;
        this.showGettingStart = true;
      }
    });

    console.log(freeEmailDomains.length);
  }

  ngOnInit() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activeRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
     )
     .subscribe((event) => {
       this.ecoDocsTitleService.setTitle(event['title']);
       this.ecoDocsTitleService.updateOgUrl(event['ogUrl']);
       //Updating Description tag dynamically with title
       this.ecoDocsTitleService.updateDescription(event['description'])
     });
  }

  getStart(Form) {
    this.loading = true;
    if (!Form.valid) {
      this._validationHandler.onSubmitCheck(Form);
      this.loading = false;
      return;
    }
    let model: GetStarted = {
      email: Form.value.email,
      password: Form.value.password,
      website_url: this.router.url.toString(),
      chosen_plan: this.chosenPlan ? this.chosenPlan : undefined,
      utm_campaign: this.activeRoute.snapshot.queryParams['utm_campaign'] ? this.activeRoute.snapshot.queryParams['utm_campaign'] : undefined,
      utm_medium: this.activeRoute.snapshot.queryParams['utm_medium'] ? this.activeRoute.snapshot.queryParams['utm_medium'] : undefined,
      utm_source: this.activeRoute.snapshot.queryParams['utm_source'] ? this.activeRoute.snapshot.queryParams['utm_source'] : undefined
    }
    this.getStartedService.startRegistration(model).subscribe(res => {
      this.loading = false;
      window.location.href = `https://app.ecodocs.io/registration-progress/${res.registration_id}`;
      this.getStartForm.reset();
    },
    err => {
      this.toastrService.error('Couldn\'t send info', 'Error' ,{positionClass: 'md-toast-top-right'});
      this.loading = false;   
    });
  }
  callNow(){
    window.location.href = "tel:+18559108235";
  }
}
