import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { ValidationHandler } from 'app/helpers/validation.handler';
import { GetStartedService } from 'app/website/services/get-started.service';
import { GetStarted } from 'app/website/models/get-started.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import * as freeEmailDomains from 'free-email-domains';

@Component({
  selector: 'site-get-start',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss']
})
export class GetStartedComponent implements OnInit {
  chosenPlan: any;
  loading: boolean = false;

  constructor(private formBuilder: FormBuilder, private _validationHandler: ValidationHandler, private getStartedService: GetStartedService,
    private Route: ActivatedRoute, private router: Router, private toastrService: ToastService) { }

  ngOnInit() {
  }


  getStartForm: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email, (c: FormControl) => {
      let domain = (c.value || '@').split('@')[1];
      if (freeEmailDomains.includes(domain)) {
        return {freeEmail: true};
      } else {
        return null;
      }
    }]],
    password: ['', [Validators.required, Validators.minLength(6)]]
  })


  getStart(Form) {
    this.loading = true;
    if (!Form.valid) {
      this._validationHandler.onSubmitCheck(Form);
      this.loading = false;
      return;
    }
    let model: GetStarted = {
      email: Form.value.email,
      password: Form.value.password,
      website_url: this.router.url.toString(),
      chosen_plan: this.chosenPlan ? this.chosenPlan : undefined,
      utm_campaign: this.Route.snapshot.queryParams['utm_campaign'] ? this.Route.snapshot.queryParams['utm_campaign'] : undefined,
      utm_medium: this.Route.snapshot.queryParams['utm_medium'] ? this.Route.snapshot.queryParams['utm_medium'] : undefined,
      utm_source: this.Route.snapshot.queryParams['utm_source'] ? this.Route.snapshot.queryParams['utm_source'] : undefined
    }
    
    this.getStartedService.startRegistration(model).subscribe(res => {
      this.loading = false;
      window.location.href = `https://app.ecodocs.io/registration-progress/${res.registration_id}`;
      this.getStartForm.reset();
    },
    err => {
      this.toastrService.error('Error signing up', 'Error' ,{positionClass: 'md-toast-top-right'});
      this.loading = false;
    });
  }

}
