import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FeatureInfo } from 'app/website/models/feature.model';
import { FeatureSummary } from 'app/website/models/feature-summary.model';
import { CarouselCaption, MediaTypes } from 'app/website/models/carousel-caption.model';
import { GetStartedService } from 'app/website/services/get-started.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IndexComponent implements OnInit {
  constructor(public getStartedService: GetStartedService) { }

  ngOnInit() {
  }

  carouselCaptions: CarouselCaption[] = [
    {
      title: 'No More Spreadsheets Mess',
      description: 'Spreadsheets are history, with EcoDocs you can ensure centralized data collection and up-to-date business dashboards and reports for informed decisions.',
      mediaUrl: '/assets/img/replace-spreadsheets.jpg',
      mediaType: MediaTypes.IMAGE,
      url:'/eliminate-spreadsheets',
      urlTitle: 'Learn More'
    },
    {
      title: 'Go Digital',
      description: 'Convert paper documents to user-friendly digital documents, build smart e-forms, and set permissions and assign roles all in one place. ',
      mediaUrl: '/assets/img/go-digital.jpg',
      mediaType: MediaTypes.IMAGE,
      url:'/go-paperless',
      urlTitle: 'Learn More'
    },
    {
      title: 'Document Management & Collaboration',
      description: 'Standardize documents used across your business and  enhance collaboration between different teams. Now you can capture, secure, and organize files and data, then easily share it with others.',
      mediaUrl: '/assets/img/document-management.jpg',
      mediaType: MediaTypes.IMAGE,
      url:'/document-management',
      urlTitle: 'Learn More'
    },
    {
      title: 'Business Process Automation',
      description: 'EcoDocs is a Business Process Automation system that helps you streamline processes such as employee onboarding, accounts payable, contract management and more.',
      mediaUrl: '/assets/img/business-process-automation.jpg',
      mediaType: MediaTypes.IMAGE,
      url:'/business-process-automation',
      urlTitle: 'Learn More'
    },
    {
      title: 'Real-time Dashboards & Reports',
      description: 'EcoDocs live visual dashboards are highly customizable and connect related data from different sources to identify areas for optimization and ensure continuous improvement.',
      mediaUrl: '/assets/img/data-reports-and-analytics.jpg',
      mediaType: MediaTypes.IMAGE,
      url:'/data-reports-and-analytics',
      urlTitle: 'Learn More'
    }
  ]
  
    feature1: FeatureInfo =  {
      imgUrl: '/assets/img/spreadsheet-mess.png',
      alt:'a messy spreadsheet file'
    }
    feature2: FeatureInfo =  {
      imgUrl: '/assets/img/paper_problems.jpg',
      alt:'a businessman looking to large pile of paperwork'
    }
    feature3: FeatureInfo ={
      imgUrl: '/assets/img/document-management-section.png',
      alt: 'EcoDocs file explorer used on a laptop screen showing document management and collaboration'
    }
    feature4: FeatureInfo = {
      imgUrl: '/assets/img/workflow_automation_feature.jpg',
      alt: 'EcoDocs workflow tree used to automate a complex business process'
    }
    feature5: FeatureInfo = {
      imgUrl: '/assets/img/dashboards-reports.png',
      alt: 'EcoDocs live dashboards rendering on a large screen'
    }
    feature6: FeatureInfo = {
      imgUrl: '/assets/img/home-eforms.png',
      alt: 'EcoDocs forms viewed on tablets and smart phones.'
    }
  
    moreFeatures: FeatureSummary[] = [
      {
        title: 'Forms Builder',
        desciption: 'Easily build complicated forms used in any industry with the drag-and-drop forms builder. No coding required!',
        icon: 'far  fa-3x fa-file-alt'
      },
      {
        title: 'Custom Workflows',
        desciption: 'Use the visual tree view workflow template builder to create highly sophisticated workflows with multi-step approval and review processes.',
        icon: 'fas fa-3x fa-sitemap'
      },
      {
        title: 'Custom Dashboards',
        desciption: 'Build customized dashboards for different users to help make the right decisions.',
        icon: 'fas fa-3x fa-tachometer-alt'
      },
      {
        title: 'Real-time Analytics',
        desciption: 'As soon as forms are filled and submitted, the filled data is available for advanced analysis and visualization.',
        icon: 'far fa-3x fa-chart-bar'
      },
      {
        title: 'Easy Search',
        desciption: 'Sort, categorize, apply OCR and text extraction for full text searching and indexing on all your documents. ',
        icon: 'fas fa-3x fa-search'
      },
      {
        title: 'Auto Validation',
        desciption: 'Your data is automatically validated when entered using Custom logic, Relation-based, Value-based, or conditional validation.',
        icon: 'far fa-3x fa-check-circle'
      },
      {
        title: 'Highly Secure',
        desciption: 'Configure your users and teams and define who has permission to what.',
        icon: 'fas fa-3x fa-user-shield'
      },
      {
        title: 'Compatibility',
        desciption: 'The system can be easily integrated with any on-premises storage through FTP, SFTP, and WebDAV.',
        icon: 'fas fa-3x fa-check-double'
      }
    ]
}
