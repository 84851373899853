import { Component, OnInit, Input } from '@angular/core';
import { FeatureSummary } from 'app/website/models/feature-summary.model';

@Component({
  selector: 'site-more-feature',
  templateUrl: './more-feature.component.html',
  styleUrls: ['./more-feature.component.scss']
})
export class MoreFeatureComponent implements OnInit {

  @Input() features: FeatureSummary[] = [];
  @Input() header: string;
  constructor() { }

  ngOnInit() {
  }

}
