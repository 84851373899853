import { Component, OnInit } from '@angular/core';
import { FeatureSummary } from 'app/website/models/feature-summary.model';
import { FeatureInfo } from 'app/website/models/feature.model';

@Component({
  selector: 'app-e-forms',
  templateUrl: './e-forms.component.html',
  styleUrls: ['./e-forms.component.scss']
})
export class EFormsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  
  featureForms: FeatureInfo ={
    imgUrl: '/assets/img/construction-eforms.png',
    alt: 'EcoDocs e-forms viewed on different tablets and mobile devices'
  }
  featureData: FeatureInfo ={
    imgUrl: '/assets/img/forms-data.jpg',
    alt: 'A man viewing data on EcoDocs on an iMac'
  }
  featureAutoValidation: FeatureInfo ={
    imgUrl: '/assets/img/validation.png',
    alt: 'email and phone number fields with invalid inputs in EcoDocs e-forms'
  }
  featureCloud: FeatureInfo ={
    imgUrl: '/assets/img/ecodocs-cloud.jpg',
    alt: 'EcoDocs files explorer showing collaboration between different users on EcoDocs cloud'
  }
  featureIntegration: FeatureInfo ={
    imgUrl: '/assets/img/integration.png',
    alt: 'logos of third-party services and protocols such as Google Drive, Dropbox, oneDrive, WebDav, FTP and SFTP that integrate with EcoDocs'
  }

  moreFeatures: FeatureSummary[] = [
    {
      title: 'Forms Builder',
      desciption: 'Easily build complicated forms used in any industry with the drag-and-drop forms builder. No coding required!',
      icon: 'far  fa-3x fa-file-alt'
    },
    {
      title: 'Custom Workflows',
      desciption: 'Use the visual tree view workflow template builder to create highly sophisticated workflows with multi-step approval and review processes.',
      icon: 'fas fa-3x fa-sitemap'
    },
    {
      title: 'Custom Dashboards',
      desciption: 'Build customized dashboards for different users to help make the right decisions.',
      icon: 'fas fa-3x fa-tachometer-alt'
    },
    {
      title: 'Real-time Analytics',
      desciption: 'As soon as forms are filled and submitted, the filled data is available for advanced analysis and visualization.',
      icon: 'far fa-3x fa-chart-bar'
    },
    {
      title: 'Easy Search',
      desciption: 'Sort, categorize, apply OCR and text extraction for full text searching and indexing on all your documents. ',
      icon: 'fas fa-3x fa-search'
    },
    {
      title: 'Auto Validation',
      desciption: 'Your data is automatically validated when entered using Custom logic, Relation-based, Value-based, or conditional validation.',
      icon: 'far fa-3x fa-check-circle'
    },
    {
      title: 'Highly Secure',
      desciption: 'Configure your users and teams and define who has permission to what.',
      icon: 'fas fa-3x fa-user-shield'
    },
    {
      title: 'Compatibility',
      desciption: 'The system can be easily integrated with any on-premises storage through FTP, SFTP, and WebDAV.',
      icon: 'fas fa-3x fa-check-double'
    }
]
}
