import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class Globals {
    public api_url_scheme: string = 'https://';
    //public api_url_scheme: string = 'http://';

    public api_suffix_path: string = '/api';
    //public api_suffix_path: string = '/backend/public/api';

    public api_public_base_url: string = 'https://api.ecodocs.io/api';
    //public api_public_base_url: string = 'http://localhost/backend/public/api';
    
    // The current company-specific api base URL to call the backend with, this is set after a user is logged in
    public api_base_url: string;
}