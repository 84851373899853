import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ScrollSpyModule, WavesModule } from 'ng-uikit-pro-standard'

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FaqComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  faqs = [
    {
      question: 'How do i sign up?',
      answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ante sem, consequat a lectus sed, hendrerit fermentum quam. Duis luctus pretium sapien eu maximus. Donec vehicula scelerisque efficitur. Ut pulvinar tristique purus at vulputate. Ut et ullamcorper libero, ut semper nisi. Donec lacus lacus, rutrum accumsan mattis sit amet, aliquet eu risus.'
    },
    {
      question: 'How do i sign up?',
      answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ante sem, consequat a lectus sed, hendrerit fermentum quam. Duis luctus pretium sapien eu maximus. Donec vehicula scelerisque efficitur. Ut pulvinar tristique purus at vulputate. Ut et ullamcorper libero, ut semper nisi. Donec lacus lacus, rutrum accumsan mattis sit amet, aliquet eu risus.'
    },
    {
      question: 'How do i sign up?',
      answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ante sem, consequat a lectus sed, hendrerit fermentum quam. Duis luctus pretium sapien eu maximus. Donec vehicula scelerisque efficitur. Ut pulvinar tristique purus at vulputate. Ut et ullamcorper libero, ut semper nisi. Donec lacus lacus, rutrum accumsan mattis sit amet, aliquet eu risus.'
    },
    {
      question: 'How do i sign up?',
      answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ante sem, consequat a lectus sed, hendrerit fermentum quam. Duis luctus pretium sapien eu maximus. Donec vehicula scelerisque efficitur. Ut pulvinar tristique purus at vulputate. Ut et ullamcorper libero, ut semper nisi. Donec lacus lacus, rutrum accumsan mattis sit amet, aliquet eu risus.'
    },
    {
      question: 'How do i sign up?',
      answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ante sem, consequat a lectus sed, hendrerit fermentum quam. Duis luctus pretium sapien eu maximus. Donec vehicula scelerisque efficitur. Ut pulvinar tristique purus at vulputate. Ut et ullamcorper libero, ut semper nisi. Donec lacus lacus, rutrum accumsan mattis sit amet, aliquet eu risus.'
    },
    {
      question: 'How do i sign up?',
      answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ante sem, consequat a lectus sed, hendrerit fermentum quam. Duis luctus pretium sapien eu maximus. Donec vehicula scelerisque efficitur. Ut pulvinar tristique purus at vulputate. Ut et ullamcorper libero, ut semper nisi. Donec lacus lacus, rutrum accumsan mattis sit amet, aliquet eu risus.'
    },
    {
      question: 'How do i sign up?',
      answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ante sem, consequat a lectus sed, hendrerit fermentum quam. Duis luctus pretium sapien eu maximus. Donec vehicula scelerisque efficitur. Ut pulvinar tristique purus at vulputate. Ut et ullamcorper libero, ut semper nisi. Donec lacus lacus, rutrum accumsan mattis sit amet, aliquet eu risus.'
    },
    {
      question: 'How do i sign up?',
      answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ante sem, consequat a lectus sed, hendrerit fermentum quam. Duis luctus pretium sapien eu maximus. Donec vehicula scelerisque efficitur. Ut pulvinar tristique purus at vulputate. Ut et ullamcorper libero, ut semper nisi. Donec lacus lacus, rutrum accumsan mattis sit amet, aliquet eu risus.'
    },
    {
      question: 'How do i sign up?',
      answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ante sem, consequat a lectus sed, hendrerit fermentum quam. Duis luctus pretium sapien eu maximus. Donec vehicula scelerisque efficitur. Ut pulvinar tristique purus at vulputate. Ut et ullamcorper libero, ut semper nisi. Donec lacus lacus, rutrum accumsan mattis sit amet, aliquet eu risus.'
    },
    {
      question: 'How do i sign up?',
      answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ante sem, consequat a lectus sed, hendrerit fermentum quam. Duis luctus pretium sapien eu maximus. Donec vehicula scelerisque efficitur. Ut pulvinar tristique purus at vulputate. Ut et ullamcorper libero, ut semper nisi. Donec lacus lacus, rutrum accumsan mattis sit amet, aliquet eu risus.'
    },
    {
      question: 'How do i sign up?',
      answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ante sem, consequat a lectus sed, hendrerit fermentum quam. Duis luctus pretium sapien eu maximus. Donec vehicula scelerisque efficitur. Ut pulvinar tristique purus at vulputate. Ut et ullamcorper libero, ut semper nisi. Donec lacus lacus, rutrum accumsan mattis sit amet, aliquet eu risus.'
    },
    {
      question: 'How do i sign up?',
      answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ante sem, consequat a lectus sed, hendrerit fermentum quam. Duis luctus pretium sapien eu maximus. Donec vehicula scelerisque efficitur. Ut pulvinar tristique purus at vulputate. Ut et ullamcorper libero, ut semper nisi. Donec lacus lacus, rutrum accumsan mattis sit amet, aliquet eu risus.'
    },
  ]
}
