import { Component, Input, OnInit } from '@angular/core';
import { WordPressPost } from 'app/website/models/word-press-post.model';
import { WordPressService } from 'app/website/services/word-press.service';

@Component({
  selector: 'app-embedded-wordpress-articles',
  templateUrl: './embedded-wordpress-articles.component.html',
  styleUrls: ['./embedded-wordpress-articles.component.scss']
})
export class EmbeddedWordpressArticlesComponent implements OnInit {

  @Input() categoryID: number;

  posts: WordPressPost[] = [];
  constructor(public wordPressService: WordPressService) { }

  ngOnInit(): void {
    this.wordPressService.getPosts(this.categoryID.toString()).subscribe((p) => {
      console.log('Posts: ', p);
      this.posts = p;
    });
  }

}
