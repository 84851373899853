import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class EcoDocsTitleService {
    constructor(private titleService: Title, private meta: Meta) {
    }

    setTitle(newTitle: string, append: boolean = false) {
     if (append){
        this.titleService.setTitle( 'EcoDocs - ' + newTitle );
     } 
     else{  
        this.titleService.setTitle(  newTitle + ' - EcoDocs' );
        }
    }

    updateOgUrl(url: string) {
        this.meta.updateTag({ name: 'og:url', content: url })
    }

    updateDescription(desc: string) {
        this.meta.updateTag({ name: 'description', content: desc })
    }
}