import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { TooltipDirective } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-roi-calculator',
  templateUrl: './roi-calculator.component.html',
  styleUrls: ['./roi-calculator.component.scss']
})
export class RoiCalculatorComponent implements OnInit {

  
  numberOfEmployees: number = 100;
  averageYearlyWage: number = 70000;
  hoursSavedPerDay: number = 2;
  uninformedDecisionsCost: number = 0;
  dailyHrsLookingForInfo: number = 1.5;
  misFiledCost: number = 0;
  archivesCost: number = 0;
  paperInkCost: number = 0;
  
  @ViewChild('popHoursSavedTip', {static: false}) popHoursSavedTip: TooltipDirective;
  @ViewChild('popUninformedDecisionsTip', {static: false}) popUninformedDecisionsTip: TooltipDirective;
  @ViewChild('popDailySearchTip', {static: false}) popDailySearchTip: TooltipDirective;
  @ViewChild('popMisfiledDocumentsTip', {static: false}) popMisfiledDocumentsTip: TooltipDirective;
  @ViewChild('popOfficeSpaceTip', {static: false}) popOfficeSpaceTip: TooltipDirective;
  @ViewChild('popPrintingTip', {static: false}) popPrintingTip: TooltipDirective;

  constructor() { }

  ngOnInit(): void {
  }
  
  getYearlySavings() {
    return this.numberOfEmployees * this.averageYearlyWage * (((this.hoursSavedPerDay + this.dailyHrsLookingForInfo) * 22 * 12) / (176 * 12)) +
            this.uninformedDecisionsCost + this.misFiledCost + this.archivesCost + this.paperInkCost;
  }

  getEcoDocsCost() {
    return (20 * 70 * 12) + 
          ((this.numberOfEmployees > 20)? (Math.min(this.numberOfEmployees, 49) - 20) * 70 * 12: 0) + 
          ((this.numberOfEmployees >= 50)? (this.numberOfEmployees - 49) * 60 * 12: 0);
  }

  getNetSavings() {
    return this.getYearlySavings() - this.getEcoDocsCost();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event) {
    if (event.target != ((this.popHoursSavedTip as any)._elementRef as ElementRef).nativeElement && this.popHoursSavedTip.isOpen)
      this.popHoursSavedTip.hide();

    if (event.target != ((this.popUninformedDecisionsTip as any)._elementRef as ElementRef).nativeElement && this.popUninformedDecisionsTip.isOpen)
      this.popUninformedDecisionsTip.hide();

    if (event.target != ((this.popDailySearchTip as any)._elementRef as ElementRef).nativeElement && this.popDailySearchTip.isOpen)
      this.popDailySearchTip.hide();

    if (event.target != ((this.popMisfiledDocumentsTip as any)._elementRef as ElementRef).nativeElement && this.popMisfiledDocumentsTip.isOpen)
      this.popMisfiledDocumentsTip.hide();

    if (event.target != ((this.popOfficeSpaceTip as any)._elementRef as ElementRef).nativeElement && this.popOfficeSpaceTip.isOpen)
      this.popOfficeSpaceTip.hide();

    if (event.target != ((this.popPrintingTip as any)._elementRef as ElementRef).nativeElement && this.popPrintingTip.isOpen)
      this.popPrintingTip.hide();

    }
}
