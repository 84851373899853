import { Component, OnInit } from '@angular/core';
import { GetStartedService } from 'app/website/services/get-started.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class SitePricingComponent implements OnInit {

  formsAnnual: string = 'free';
  filesAnnual: string = 'free';
  workflowsAnnual: string = 'free';
  dashboardsAnnual: string = 'free';

  formsMonthly: string = 'free';
  filesMonthly: string = 'free';
  workflowsMonthly: string = 'free';
  dashboardsMonthly: string = 'free';

  constructor(public getStartedService: GetStartedService) {

   }

   choosePlan(isAnnual: boolean) {
    let plan: string = (isAnnual)? 'Annual:': 'Monthly';
    plan = plan + ',Forms:' + (isAnnual)? this.formsAnnual.split('.').pop(): this.formsMonthly.split('.').pop();
    plan = plan + ',Files:' + (isAnnual)? this.filesAnnual.split('.').pop(): this.filesMonthly.split('.').pop();
    plan = plan + ',Workflows:' + (isAnnual)? this.workflowsAnnual.split('.').pop(): this.workflowsMonthly.split('.').pop();
    plan = plan + ',Dashboards:' + (isAnnual)? this.dashboardsAnnual.split('.').pop(): this.dashboardsMonthly.split('.').pop();

    this.getStartedService.choosePlan(plan)
   }

  ngOnInit() {
    this.formsAnnual = 'free';
    this.filesAnnual = 'free';
    this.workflowsAnnual = 'free';
    this.dashboardsAnnual = 'free';        

    this.formsMonthly = 'free';
    this.filesMonthly = 'free';
    this.workflowsMonthly = 'free';
    this.dashboardsMonthly = 'free';   
  }

  scrollToFeaturesDetails(event: Event) {
    event.preventDefault();
    document.querySelector('.features-details').scrollIntoView({behavior: 'smooth'});
  }

  scrollToExtraQuotasPrices(event: Event) {
    event.preventDefault();
    document.querySelector('.extra-quotas-prices').scrollIntoView({behavior: 'smooth'});
  }
  callNow(){
    window.location.href = "tel:+18559108235";
  }
  annualTotalBeforeDiscount() {
    let formsPrice: number = 0;
    if (this.formsAnnual == 'all_features.forms.basic') {
      formsPrice = 250 / 12;
    } else if (this.formsAnnual == 'all_features.forms.advanced') {
      formsPrice = 350 / 12;
    } else if (this.formsAnnual == 'all_features.forms.premium') {
      formsPrice = 390 / 12;
    }
    
    let filesPrice: number = 0;
    if (this.filesAnnual == 'all_features.files.basic') {
      filesPrice = 190 / 12;
    } else if (this.filesAnnual == 'all_features.files.advanced') {
      filesPrice = 250 / 12;
    } 
    
    let workflowsPrice: number = 0;
    if (this.workflowsAnnual == 'all_features.workflows.basic') {
      workflowsPrice = 290 / 12;
    } else if (this.workflowsAnnual == 'all_features.workflows.advanced') {
      workflowsPrice = 390 / 12;
    } 

    let dashboardsPrice: number = 0;
    if (this.dashboardsAnnual == 'all_features.dashboards.basic') {
      dashboardsPrice = 190 / 12;
    } else if (this.dashboardsAnnual == 'all_features.dashboards.advanced') {
      dashboardsPrice = 290 / 12;
    }

    return formsPrice + filesPrice + workflowsPrice + dashboardsPrice;
  }

  monthlyTotalBeforeDiscount() {
    let formsPrice: number = 0;
    if (this.formsMonthly == 'all_features.forms.basic') {
      formsPrice = 25;
    } else if (this.formsMonthly == 'all_features.forms.advanced') {
      formsPrice = 35;
    } else if (this.formsMonthly == 'all_features.forms.premium') {
      formsPrice = 39;
    }
    
    let filesPrice: number = 0;
    if (this.filesMonthly == 'all_features.files.basic') {
      filesPrice = 19;
    } else if (this.filesMonthly == 'all_features.files.advanced') {
      filesPrice = 25;
    } 
    
    let workflowsPrice: number = 0;
    if (this.workflowsMonthly == 'all_features.workflows.basic') {
      workflowsPrice = 29;
    } else if (this.workflowsMonthly == 'all_features.workflows.advanced') {
      workflowsPrice = 39;
    } 

    let dashboardsPrice: number = 0;
    if (this.dashboardsMonthly == 'all_features.dashboards.basic') {
      dashboardsPrice = 19;
    } else if (this.dashboardsMonthly == 'all_features.dashboards.advanced') {
      dashboardsPrice = 29;
    }

    return formsPrice + filesPrice + workflowsPrice + dashboardsPrice;
  }

  annualDiscount() {

    let discountPercent: number = 0;

    
    let numPaid: number = 0;

    if (this.formsAnnual != 'free') {
      numPaid++;
    }
    if (this.filesAnnual != 'free') {
      numPaid++;
    }
    if (this.workflowsAnnual != 'free') {
      numPaid++;
    }
    if (this.dashboardsAnnual != 'free') {
      numPaid++;
    }

    if (numPaid == 2) {
      discountPercent = 0.1;
    } else if (numPaid == 3) {
      discountPercent = 0.17;
    } else if (numPaid == 4) {
      discountPercent = 0.25;
    }

    return discountPercent * this.annualTotalBeforeDiscount();
  }

  monthlyDiscount() {
    let discountPercent: number = 0;

    let numPaid: number = 0;

    if (this.formsMonthly != 'free') {
      numPaid++;
    }
    if (this.filesMonthly != 'free') {
      numPaid++;
    }
    if (this.workflowsMonthly != 'free') {
      numPaid++;
    }
    if (this.dashboardsMonthly != 'free') {
      numPaid++;
    }

    if (numPaid == 2) {
      discountPercent = 0.1;
    } else if (numPaid == 3) {
      discountPercent = 0.17;
    } else if (numPaid == 4) {
      discountPercent = 0.25;
    }

    return discountPercent * this.monthlyTotalBeforeDiscount();
  }
  pricingComaprison = [
    {
      sectionHeader: 'Intelligent Automation (AI-Powered Workflow Automation)',
      comparison: [
        {
          comparisonTitle: 'Single-step workflows',
          tick: true,
        },
        {
          comparisonTitle: 'Approval workflows',
          tick: true,
        },
        {
          comparisonTitle: 'Review workflows',
          tick: true,
        },
        {
          comparisonTitle: 'Multi-step workflows',
          tick: true,
        },
        {
          comparisonTitle: 'Visual workflow rule builder',
          tick: true,
        },
        {
          comparisonTitle: 'Clone  workflows',
          tick: true,
        },
        {
          comparisonTitle: 'Decision-based flow paths',
          tick: true,
        },
        {
          comparisonTitle: 'Auto actions',
          tick: true,
        },
        {
          comparisonTitle: 'Auto-start workflows',
          tick: true,
        },
        {
          comparisonTitle: 'Automated decisions',
          tick: true,
        },
        {
          comparisonTitle: 'Robotic Process Automation (RPA)',
          tick: true,
        },
        {
          comparisonTitle: 'Automate third party web-based systems',
          tick: true,
        },
      ]
    },
    {
      sectionHeader: 'AI-Powered Document Processing',
      comparison: [
        {
          comparisonTitle: 'OCR & Text extraction',
          tick: true,
        },
        {
          comparisonTitle: 'AI Invoice Processor',
          tick: "addon",
        },
        {
          comparisonTitle: 'AI ID Processor',
          tick: "addon",
        },
        {
          comparisonTitle: 'AI Form Processor',
          tick: "addon",
        },
        {
          comparisonTitle: 'AI Tabular Data Processor',
          tick: "addon",
        },
        {
          comparisonTitle: 'AI Natural Language Processor',
          tick: "addon",
        },
        {
          comparisonTitle: 'AI Image Processor',
          tick: "addon",
        }
      ]
    },
    {
      sectionHeader: 'Business Intelligence, Reports & Analytics',
      comparison: [
        {
          comparisonTitle: 'Custom dashboards',
          tick: true,
        },
        {
          comparisonTitle: 'Charts',
          tick: true,
        },
        {
          comparisonTitle: 'Metrics',
          tick: true,
        },
        {
          comparisonTitle: 'Data tables',
          tick: true,
        },
        {
          comparisonTitle: 'Visualize data on a map',
          tick: true,
        },
        {
          comparisonTitle: 'Data monitoring and alerts',
          tick: true,
        },
        {
          comparisonTitle: 'Insights and analytics',
          tick: true,
        },
        {
          comparisonTitle: 'Real-time access to data',
          tick: true,
        },
        {
          comparisonTitle: 'Export data to spreadsheets',
          tick: true,
        },
        {
          comparisonTitle: 'Schedule reports',
          tick: true,
        }
      ]
    },
    {
      sectionHeader: 'Data Management',
      comparison: [
        {
          comparisonTitle: 'Collect data through forms',
          tick: true,
        },
        {
          comparisonTitle: 'Drag-and-drop forms builder',
          tick: true,
        },
        {
          comparisonTitle: 'Basic fields (Text, Numbers, Dates...etc)',
          tick: true,
        },
        {
          comparisonTitle: 'Advanced fields (Signatures, Checkboxes, Images...etc)',
          tick: true,
        },
        {
          comparisonTitle: 'Premium fields (Maps, Geo-Locations, Data Dropdowns...etc)',
          tick: true,
        },
        {
          comparisonTitle: 'Basic validation',
          tick: true,
        },
        {
          comparisonTitle: 'Custom validation logic',
          tick: true,
        },
        {
          comparisonTitle: 'Calculated values & formulas',
          tick: true,
        },
        {
          comparisonTitle: 'Conditional formatting',
          tick: true,
        },
        {
          comparisonTitle: 'Spreadsheet to form converter',
          tick: true,
        },
        {
          comparisonTitle: 'Spreadsheet data import',
          tick: true,
        },
        {
          comparisonTitle: 'Historical data import wizard',
          tick: true,
        },
        {
          comparisonTitle: 'Connected Apps external data submits',
          tick: true,
        },
        {
          comparisonTitle: 'Connected Apps external data queries',
          tick: true,
        }
      ]
    },
    {
      sectionHeader: 'Files Management & Collaboration',
      comparison: [
        {
          comparisonTitle: 'EcoDocs Cloud',
          tick: true,
        },
        {
          comparisonTitle: 'Compliant infrastructure (SOC, PCI DSS, FedRAMP, HIPAA)',
          tick: true,
        },
        {
          comparisonTitle: 'Metadata file sorting',
          tick: true,
        },
        {
          comparisonTitle: 'File versioning',
          tick: true,
        },
        {
          comparisonTitle: 'Lifecycle rules',
          tick: true,
        },
        {
          comparisonTitle: 'Comments & collaboration',
          tick: true,
        },
        {
          comparisonTitle: 'Auto backups & redundancy',
          tick: true,
        },
        {
          comparisonTitle: 'Linking to on-premise data ',
          tick: true,
        },
        {
          comparisonTitle: 'Connected Apps triggers ',
          tick: true,
        },
        {
          comparisonTitle: 'Instant indexing',
          tick: true,
        },
        {
          comparisonTitle: 'Full-textual search',
          tick: true,
        }
      ]
    },
    {
      sectionHeader: 'Microsoft 365 Integration',
      comparison: [
        {
          comparisonTitle: 'Microsoft Word document editing',
          tick: true,
        },
        {
          comparisonTitle: 'Microsoft Excel editing',
          tick: true,
        },
        {
          comparisonTitle: 'Microsoft PowerPoint editing',
          tick: true,
        },
        {
          comparisonTitle: 'Run PowerPoint presentations',
          tick: true,
        }
      ]
    },
    {
      sectionHeader: 'Users & Teams',
      comparison: [
        {
          comparisonTitle: 'Add users',
          tick: true,
        },
        {
          comparisonTitle: 'Create teams',
          tick: true,
        },
        {
          comparisonTitle: 'External users',
          tick: true,
        },
        {
          comparisonTitle: 'Granular access permissions',
          tick: true,
        }
      ]
    },
  ]
}
