import { Component, OnInit } from '@angular/core';
import { GetStartedService } from 'app/website/services/get-started.service';

@Component({
  selector: 'site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.scss']
})
export class SiteFooterComponent implements OnInit {

  currentYear: number = (new Date()).getFullYear();

  constructor(public getStartedService: GetStartedService) { }

  ngOnInit() {
  }
  callNow(){
    window.location.href = "tel:+18559108235";
  }
}
