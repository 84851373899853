import { Component, OnInit } from '@angular/core';
import { FeatureInfo } from 'app/website/models/feature.model';
import { FeatureSummary } from 'app/website/models/feature-summary.model';

@Component({
  selector: 'app-business-process-automation',
  templateUrl: './automate-workflows.component.html',
  styleUrls: ['./automate-workflows.component.scss']
})
export class AutomateWorkflowsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
     
  }

  feature1: FeatureInfo ={
    imgUrl: '/assets/img/automation_must.jpg',
    alt: 'man using hologram to automate a business process'
  }
  feature2: FeatureInfo ={
    imgUrl: '/assets/img/workflow-tree.jpg',
    alt: 'a business woman using EcoDocs workflow editor on macbook'
  }
  feature3: FeatureInfo ={
    imgUrl: '/assets/img/keep_simple.jpg',
    alt: 'EcoDocs workflow tree representing complex business process '
  }
  feature4: FeatureInfo ={
    imgUrl: '/assets/img/advanced_easy.jpg',
    alt: 'woman using hologram to manage a business process'
  }
  feature5: FeatureInfo ={
    imgUrl: '/assets/img/even_more.jpg',
    alt: 'green pen ticking a checklist'
  }

  moreFeatures: FeatureSummary[] = [
    {
      title: 'Forms Builder',
      desciption: 'Easily build complicated forms used in any industry with the drag-and-drop forms builder. No coding required!',
      icon: 'far  fa-3x fa-file-alt'
    },
    {
      title: 'Custom Workflows',
      desciption: 'Use the visual tree view workflow template builder to create highly sophisticated workflows with multi-step approval and review processes.',
      icon: 'fas fa-3x fa-sitemap'
    },
    {
      title: 'Custom Dashboards',
      desciption: 'Build customized dashboards for different users to help make the right decisions.',
      icon: 'fas fa-3x fa-tachometer-alt'
    },
    {
      title: 'Real-time Analytics',
      desciption: 'As soon as forms are filled and submitted, the filled data is available for advanced analysis and visualization.',
      icon: 'far fa-3x fa-chart-bar'
    },
    {
      title: 'Easy Search',
      desciption: 'Sort, categorize, apply OCR and text extraction for full text searching and indexing on all your documents. ',
      icon: 'fas fa-3x fa-search'
    },
    {
      title: 'Auto Validation',
      desciption: 'Your data is automatically validated when entered using Custom logic, Relation-based, Value-based, or conditional validation.',
      icon: 'far fa-3x fa-check-circle'
    },
    {
      title: 'Highly Secure',
      desciption: 'Configure your users and teams and define who has permission to what.',
      icon: 'fas fa-3x fa-user-shield'
    },
    {
      title: 'Compatibility',
      desciption: 'The system can be easily integrated with any on-premises storage through FTP, SFTP, and WebDAV.',
      icon: 'fas fa-3x fa-check-double'
    }
]
}
