import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-onboarding-section',
  templateUrl: './onboarding-section.component.html',
  styleUrls: ['./onboarding-section.component.scss']
})
export class OnboardingSectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
