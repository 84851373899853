import { Component, OnInit } from '@angular/core';
import { FeatureInfo } from 'app/website/models/feature.model';
import { FeatureSummary } from 'app/website/models/feature-summary.model';
import { GetStartedService } from 'app/website/services/get-started.service';

@Component({
  selector: 'app-accounting-finance',
  templateUrl: './accounting-finance.component.html',
  styleUrls: ['./accounting-finance.component.scss']
})
export class AccountingFinanceComponent implements OnInit {

  constructor(public getStartedService: GetStartedService) { }

  ngOnInit(): void {
  }
  featureInvoiceWorkflow: FeatureInfo ={
    imgUrl: '/assets/img/ap-process.jpg',
    alt: 'Financial employee working on laptop'
  }
  featureCommunications: FeatureInfo = {
    imgUrl: '/assets/img/invoice-review.jpg',
    alt: 'Accountant reviewing invoice on tablet'
  }
  featureEforms: FeatureInfo = {
    imgUrl: '/assets/img/custom-dashboards.png',
    alt: 'Accounting and finance dashboards'
  }
  featureDocumentManagement: FeatureInfo = {
    imgUrl: '/assets/img/contruction-collaboration.jpg',
    alt: 'three engineers and construction workers collaborating using EcoDocs on tablets onsite'
  }
  featureDashboardsAnalytics: FeatureInfo = {
    imgUrl: '/assets/img/construction-dashboards.png',
    alt: 'a construction engineer holding and pointing to a laptop showing EcoDcs live dashboards'
  }
  moreFeatures: FeatureSummary[] = [
    {
      title: 'Highly Secure',
      desciption: 'Configure your users and teams and define who has permission to what.',
      icon: 'fas fa-3x fa-user-shield'
    },
    {
      title: 'Easy Search',
      desciption: 'Sort, categorize, apply OCR and text extraction for full text searching and indexing on all your documents. ',
      icon: 'fas fa-3x fa-search'
    },
    {
      title: 'Compatibility',
      desciption: 'The system can be easily integrated with any on-premises storage through FTP, SFTP, and WebDAV.',
      icon: 'fas fa-3x fa-check-double'
    },
    {
      title: 'Forms Builder',
      desciption: 'Easily build complicated forms used in any industry with the drag-and-drop forms builder. No coding required!',
      icon: 'fab fa-3x fa-wpforms'
    },
    {
      title: 'Auto Validation',
      desciption: 'Your data is automatically validated when entered using Custom logic, Relation-based, Value-based, or conditional validation.',
      icon: 'far fa-3x fa-check-circle'
    },
    {
      title: 'Custom Workflows',
      desciption: 'Use the visual tree view workflow template builder to create highly sophisticated workflows with multi-step approval and review processes.',
      icon: 'fas fa-3x fa-sitemap'
    },
    {
      title: 'Custom Dashboards',
      desciption: 'Build customized dashboards for different users to help make the right decisions.',
      icon: 'fas fa-3x fa-tachometer-alt'
    },
    {
      title: 'Real-time Analytics',
      desciption: 'As soon as forms are filled and submitted, the filled data is available for advanced analysis and visualization.',
      icon: 'far fa-3x fa-chart-bar'
    }
  ]
}
