export interface CarouselCaption{
    title: string;
    description: string;
    urlTitle?: string;
    url?: string;
    mediaUrl?: string;
    mediaType?: MediaTypes;
}

export enum MediaTypes{
    VIDEO= 1,
    IMAGE = 2
}