import { Component, OnInit } from '@angular/core';
import { GetStartedService } from 'app/website/services/get-started.service';

@Component({
  selector: 'app-training-and-development',
  templateUrl: './training-and-development.component.html',
  styleUrls: ['./training-and-development.component.scss']
})
export class TrainingAndDevelopmentComponent implements OnInit {

  constructor(public getStartedService: GetStartedService) { }

  ngOnInit(): void {
  }

}
