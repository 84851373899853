import { Component, OnInit } from '@angular/core';
import { FeatureSummary } from 'app/website/models/feature-summary.model';
import { FeatureInfo } from 'app/website/models/feature.model';

@Component({
  selector: 'app-use-case',
  templateUrl: './use-case.component.html',
  styleUrls: ['./use-case.component.scss']
})
export class UseCaseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  
  feature2: FeatureInfo ={
    title: 'Create Your File Zone',
    imgUrl: '/assets/img/builder-hero.png',
    moreUrl: 'learnMore',
  }
  feature3: FeatureInfo = {
    title: 'Create Your File Zone',
    imgUrl: '/assets/img/builder-hero.png',
    moreUrl: 'learnMore',
    description: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud officia deserunt mollit exercitation.`
  }
  moreFeatures: FeatureSummary[] = [
    {
      title: 'Clean Design',
      desciption: 'Voluptatem ad provident non veritatis beatae cupiditate amet reiciendis.',
      icon: 'far fa-thumbs-up'
    },
    {
      title: 'Item Support',
      desciption: 'Voluptatem ad provident non veritatis beatae cupiditate amet reiciendis.',
      icon: 'fas fa-crown'
    },
    {
      title: 'Responsive Design',
      desciption: 'Voluptatem ad provident non veritatis beatae cupiditate amet reiciendis.',
      icon: 'fas fa-tablet-alt'
    },
    {
      title: 'Bootstrap Based',
      desciption: 'Voluptatem ad provident non veritatis beatae cupiditate amet reiciendis.',
      icon: 'fas fa-laptop-code'
    }
  ]

}
