import { Pipe, PipeTransform } from '@angular/core';
import { basename } from 'path';
/*
* Returns the basename of a path
*/
@Pipe({name: 'basename'})
export class BasenamePipe implements PipeTransform {
  transform(value: number): string {
    return basename(value);
  }
}