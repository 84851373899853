import { Component, OnInit } from '@angular/core';
import { FeatureInfo } from 'app/website/models/feature.model';
import { FeatureSummary } from 'app/website/models/feature-summary.model';

@Component({
  selector: 'app-inspection-auditing',
  templateUrl: './inspection-auditing.component.html',
  styleUrls: ['./inspection-auditing.component.scss']
})
export class InspectionAuditingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  featureInvoiceWorkflow: FeatureInfo ={
    imgUrl: '/assets/img/time_money.jpg',
    alt: 'time and money saved'
  }
  featureCommunications: FeatureInfo = {
    imgUrl: '/assets/img/inspection-human.jpg',
    alt: 'an inspector using EcoDocs on an iPad to finish his inspection report digitally'
  }
  featureEforms: FeatureInfo = {
    imgUrl: '/assets/img/inspection-forms.jpg',
    alt: 'Inspection forms opened in EcoDocs on a smart phone and a tablet'
  }
  featureDocumentManagement: FeatureInfo = {
    imgUrl: '/assets/img/export_reports.jpg',
    alt: 'Inspector viewing EcoDocs live dashboards and inspection reports on a laptop'
  }
  featureDashboardsAnalytics: FeatureInfo = {
    imgUrl: '/assets/img/reports_analytics_feature.jpg',
    alt: 'representaion of EcoDocs live dashboards and reports'
  }
  moreFeatures: FeatureSummary[] = [
    {
      title: 'Highly Secure',
      desciption: 'Configure your users and teams and define who has permission to what.',
      icon: 'fas fa-3x fa-user-shield'
    },
    {
      title: 'Easy Search',
      desciption: 'Sort, categorize, apply OCR and text extraction for full text searching and indexing on all your documents. ',
      icon: 'fas fa-3x fa-search'
    },
    {
      title: 'Compatibility',
      desciption: 'The system can be easily integrated with any on-premises storage through FTP, SFTP, and WebDAV.',
      icon: 'fas fa-3x fa-check-double'
    },
    {
      title: 'Forms Builder',
      desciption: 'Easily build complicated forms used in any industry with the drag-and-drop forms builder. No coding required!',
      icon: 'fab fa-3x fa-wpforms'
    },
    {
      title: 'Auto Validation',
      desciption: 'Your data is automatically validated when entered using Custom logic, Relation-based, Value-based, or conditional validation.',
      icon: 'far fa-3x fa-check-circle'
    },
    {
      title: 'Custom Workflows',
      desciption: 'Use the visual tree view workflow template builder to create highly sophisticated workflows with multi-step approval and review processes.',
      icon: 'fas fa-3x fa-sitemap'
    },
    {
      title: 'Custom Dashboards',
      desciption: 'Build customized dashboards for different users to help make the right decisions.',
      icon: 'fas fa-3x fa-tachometer-alt'
    },
    {
      title: 'Real-time Analytics',
      desciption: 'As soon as forms are filled and submitted, the filled data is available for advanced analysis and visualization.',
      icon: 'far fa-3x fa-chart-bar'
    }
  ]
}
