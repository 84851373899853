import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-try-ecodocs',
  templateUrl: './try-ecodocs.component.html',
  styleUrls: ['./try-ecodocs.component.scss']
})
export class TryEcodocsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
