import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Question, UserAnswer, Answer } from 'app/website/models/question.model';

@Component({
  selector: 'site-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {

  @Input() question: Question;
  @Output() questionChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() userAnswerEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() hideModal: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  next(answer: Answer) {
    let userAnswer: UserAnswer = {
      questionText: this.question.question,
      answerText: answer.answerText
    };
    //emit answer
    let answerSubmittion = JSON.parse(JSON.stringify(userAnswer));
    this.userAnswerEmitter.emit(answerSubmittion);

    if (answer.nextQuestion) {
      this.question = answer.nextQuestion;
      this.questionChange.emit(answer.nextQuestion);
    }
    else {
      this.setOldUser(true);
      this.hideMyModal();
    }
  }

  hideMyModal() {
    this.hideModal.emit(true);
  }

  setOldUser(value){
    localStorage.setItem('oldUser', value.toString());
  }

  skip(){
    this.setOldUser(false);
    this.hideMyModal();
  }

}
