import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'app/services/globals';
import { of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { environment } from 'environments/environment';
import { ActivatedRoute } from '@angular/router';

declare var gtag: Function;
declare var fbq: Function;

@Injectable({
  providedIn: 'root'
})
export class GetStartedService {

  constructor(private http: HttpClient, private globals: Globals, private titleService: Title,
            private activatedRoute: ActivatedRoute) { }

  choosenPlan: Subject<string> = new Subject<''>() ;

  launchCalendly() {
    (window as any).Calendly.initPopupWidget({url: 'https://calendly.com/d/gsj-3ts-fmg?primary_color=ff0004'});
  }
  
  startRegistration(form){
    return this.http.post<any>(`${this.globals.api_public_base_url}/register`, form).pipe(tap(()=> {
      try {
        if (typeof gtag === 'function') {
          gtag('event', 'Signup', {
            event_category: 'Leads',
            event_label: this.titleService.getTitle()
          });
        }
      } catch (e) {
        console.log(e);
      }

      try {
        if (typeof fbq === 'function') {
          fbq('track', 'Lead', {
            content_category: 'Signup',
            content_name: this.titleService.getTitle()
          });
        }
      } catch (e) {
        console.log(e);
      }

      try {
        if (this.activatedRoute.snapshot.queryParams['utm_source'] == 'capterra' && environment.production) {
          const script1 = document.createElement('script');
          script1.innerHTML = `
          (function() {
            var capterra_vkey = '063f81a7c10ddba44212428b17834d22',
            capterra_vid = '2140647',
            capterra_prefix = (('https:' == document.location.protocol)
              ? 'https://ct.capterra.com' : 'http://ct.capterra.com');
            var ct = document.createElement('script');
            ct.type = 'text/javascript';
            ct.async = true;
            ct.src = capterra_prefix + '/capterra_tracker.js?vid='
              + capterra_vid + '&vkey=' + capterra_vkey;
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(ct, s);
          })();
          `;
          document.head.appendChild(script1);
        }
      } catch (ex) {
        console.error('Error appending capterra tracking');
        console.error(ex);
      } 
    }));
  }

  choosePlan(plan){
    this.choosenPlan.next(plan);
  }

  reportPageview(contact_id: string, page_path: string) {
    return this.http.post(`${this.globals.api_public_base_url}/page_view`, {contact_id: contact_id, page_path: page_path});
  }
}
