import { Component, OnInit, Input } from '@angular/core';
import { FeatureInfo } from 'app/website/models/feature.model';

@Component({
  selector: 'site-feature-section',
  templateUrl: './feature-section.component.html',
  styleUrls: ['./feature-section.component.scss']
})
export class FeatureSectionComponent implements OnInit {

  @Input() info: FeatureInfo;
  @Input() imgPosition: string;
  @Input() template;
  @Input() imgClass: string = '';

  constructor() { }

  ngOnInit() {
  }

}
