
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from '../shared/shared.module';

import { BasenamePipe } from 'app/helpers/basename.pipe';
import { TimeAgoPipe } from 'app/helpers/time-ago.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
  ],
  declarations: [
    BasenamePipe,
    TimeAgoPipe
  ],
  exports: [
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class ViewsModule { }
